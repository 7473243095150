import React from 'react';
import loadable from '@loadable/component';

import Layout from 'components/shared/Layout';
import SEO from 'components/shared/Seo';

const PageNotFound = loadable(() =>
  import('../components/organisms/PageNotFound')
);

const ErrorTemplate = () => {
  return (
    <Layout className="error" footerType={'compact'}>
      <SEO title="Page not found" />
      <PageNotFound />
    </Layout>
  );
};

export default ErrorTemplate;
